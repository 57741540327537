<template>
  <v-progress-circular
    :size="loaderSize"
    :width="loaderWidth"
    indeterminate
    color="primary"
  ></v-progress-circular>
</template>

<script>
export default {
  data: () => ({
    loaderSize: 15,
    loaderWidth: 2,
  }),
};
</script>
