<template>
  <v-container class="content-container px-0">
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="10" sm="8" md="6" lg="6" class="form-col">
        <v-row class="text-center">
          <v-col cols="12" class="text-h3">{{ $t("resetPassword.title") }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model.trim="authentication.email"
              :rules="emailRules"
              :label="$t('resetPassword.email')"
              placeholder=" "
              outlined
              hide-details
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="authentication.password"
              :rules="validationRulesPassword"
              type="password"
              placeholder=" "
              autocomplete="new-password"
              :hint="$t('resetPassword.passwordHint')"
              persistent-hint
              outlined
            >
              <template slot="label">
                {{ $t("resetPassword.password") }}<RedAsterisk></RedAsterisk> </template
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model.trim="authentication.password_confirmation"
              type="password"
              :rules="secondPasswordRules"
              placeholder=" "
              autocomplete="new-password"
              required
              outlined
              validate-on-blur
            >
              <template slot="label"
                >{{ $t("resetPassword.confirmPassword") }}<RedAsterisk></RedAsterisk>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              id="resetPassword-btn"
              @click="resetPassword"
              color="green"
              large
              block
              :disabled="isResetPassword"
            >
              <LoadingSpinnerInline v-if="isResetPassword"></LoadingSpinnerInline>
              <template v-else>{{ $t("resetPassword.resetPassword") }}</template>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import Bus from "@/bus";
import UserApi from "@/api/UserApi";
import PasswordApi from "@/api/PasswordApi";
import LoadingSpinnerInline from "@/components/Layout/LoadingSpinnerInline";
import RedAsterisk from "@/components/Layout/RedAsterisk";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  components: {
    LoadingSpinnerInline,
    RedAsterisk,
  },
  data: () => ({
    authentication: {
      email: null,
      password: null,
      password_confirmation: null,
      token: null,
    },

    isResetPassword: false,
    passwordApi: new PasswordApi(),
  }),
  computed: {
    secondPasswordRules(v) {
      return [
        (v) => !!v || this.$t("resetPassword.rule.secondPasswordRule.required"),
        (v) =>
          this.authentication.password == v ||
          this.$t("resetPassword.rule.secondPasswordRule.passwordDoesNotMatch"),
      ];
    },
    emailRules() {
      return [(v) => !!v || this.$t("resetPassword.rule.emailRule")];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      if (this.$route.query.token != null || typeof this.$route.query.token != "undefined") {
        this.authentication.token = await this.$route.query.token;
      }
      if (this.$route.query.email != null || typeof this.$route.query.email != "undefined") {
        this.authentication.email = await this.$route.query.email;
      }
    },
    async resetPassword() {
      this.isResetPassword = true;
      this.authentication.email = await this.authentication.email.toLowerCase();

      const result = await this.passwordApi.resetPassword(this.authentication);
      this.isResetPassword = false;

      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        return;
      }
      if (result.success == false) {
        Bus.$emit("alert", "error", this.$t("resetPassword.errorMessage.resetPassword"));
        return;
      }
      if (result.success == true) {
        Bus.$emit("alert", "success", this.$t("resetPassword.successMessage.resetPassword"));
        this.$router.push("/signin");
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$font-color: #444444;

.text-h3 {
  color: $font-color;
}
.form-col > .row:not(:first-child) {
  padding-top: 1rem;
}
::v-deep .v-text-field--outlined {
  .v-input__slot {
    min-height: 5.5rem;
  }
  label {
    color: $font-color;
    font-size: 1.5rem;
    min-height: 2rem;
  }
  input {
    min-height: 4.5rem;
    margin-top: 1rem;
    font-size: 2rem;
    color: $font-color;
  }
}
#resetPassword-btn {
  color: white;
  font-size: 1.5rem;
  height: 4rem !important;
}
.forgot-btn {
  font-size: 1.1rem;
}
</style>
