import axios from "axios";
import Api from "./Api";
import store from "@/store";
import { app } from "@/main.js";

class UserApi extends Api {
  constructor() {
    super("user", "user");
  }

  async authenticate(authenticationData) {
    const errorText = app.$i18n.t("errorMessage.userApi.authentication");

    let result;
    try {
      let url = `/${this.endpoint}/authenticate`;
      result = await axios.post(url, authenticationData);

      if (result.status === 200) {
        const token = result.data.token;
        axios.defaults.headers.common["Authorization"] = token;
        store.commit("storeAuthentication", result.data);
        return result.data;
      }
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }

  async registerMember(memberData) {
    let errorText = app.$i18n.t("errorMessage.userApi.registration");

    try {
      let url = `/${this.endpoint}/register-journalist`;
      const result = await axios.post(url, memberData);

      if (result.status !== 200) {
        console.error(errorText);
        return { error: errorText };
      }

      if (result.data.error) {
        if (result.data.error === "emailAlreadyExists") {
          errorText = app.$i18n.t("errorMessage.userApi.emailAlreadyExists");
        } else if (result.data.error === "userAlreadyExists") {
          errorText = app.$i18n.t("errorMessage.userApi.userAlreadyExists");
        }
        return { error: errorText };
      }
      return result.data;
    } catch (e) {
      console.error(errorText);
      console.error(e);
      return { error: errorText };
    }
  }

  async registerNewMember(memberData) {
    let errorText = app.$i18n.t("errorMessage.userApi.registration");

    try {
      let url = `/${this.endpoint}/register-new-journalist`;
      const result = await axios.post(url, memberData);

      if (result.status !== 200) {
        console.error(errorText);
        return { error: errorText };
      }

      if (result.data.error) {
        if (result.data.error === "emailAlreadyExists") {
          errorText = app.$i18n.t("errorMessage.userApi.emailAlreadyExists");
        }
        return { error: errorText };
      }
      return result.data;
    } catch (e) {
      console.error(errorText);
      console.error(e);
      return { error: errorText };
    }
  }

  async getUserProfile(userId) {
    let result;
    // const errorText = "Failed Retriving User Profile";
    const errorText = app.$i18n.t("errorMessage.userApi.registration");

    try {
      let url = `/${this.endpoint}/user-profile/${userId}`;
      result = await axios.get(url);
      if (result.status === 200) {
        return result.data;
      }
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }

  async editUserProfile(userData) {
    // const errorText = "Updating User Profile failed";
    const errorText = app.$i18n.t("errorMessage.userApi.updatingUserProfile");

    try {
      let url = `/${this.endpoint}/user-profile`;
      const result = await axios.post(url, userData);

      if (result.status === 200) {
        return result.data;
      } else {
        return { error: errorText };
      }
    } catch (e) {
      console.log(e);
      return { error: errorText };
    }
  }
}

export default UserApi;
