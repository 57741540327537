import axios from "axios";
import Api from "./Api";
import store from "@/store";
import { app } from "@/main.js";

class PasswordApi extends Api {
  constructor() {
    super("password", "password");
  }

  async forgotPassword(email) {
    // const errorText = "Forgot Password failed";
    const errorText = app.$i18n.t("errorMessage.passwordApi.forgotPassword");
    const locale = store.getters.language;
    let result;
    try {
      let url = `/${this.endpoint}/email`;
      result = await axios.post(url, { email, locale });

      if (result.status === 200) {
        //const user = resp.data.user;
        return result.data;
      }
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }
  async resetPassword(authenticationData) {
    // const errorText = "Password Reset failed";
    const errorText = app.$i18n.t("errorMessage.passwordApi.passwordReset");

    let result;
    try {
      let url = `/${this.endpoint}/reset`;
      result = await axios.post(url, authenticationData);

      if (result.status === 200) {
        return result.data;
      }
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }
  async getEmailByToken(token) {
    // const errorText = "Failed to Retrieve Email";
    const errorText = app.$i18n.t("errorMessage.passwordApi.retrieveEmail");

    let result;
    try {
      let url = `/${this.endpoint}/email/${token}`;
      result = await axios.get(url);

      if (result.status === 200) {
        return result.data;
      }
      return { error: errorText };
    } catch (e) {
      console.log(e);
      if (e.response.status === 400 && e.response.data.error) {
        return e.response.data;
      }
      return { error: errorText };
    }
  }
}

export default PasswordApi;
